import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  CarouselArea,
} from './banner.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        bannerData {
          discount
          discountLabel
          title
          text
          carousel {
            id
            title
            thumb_url {
              publicURL
            }
            link
          }
          lists {
            Id
            Title
            TitleKey
            Poster
            Type
            Link
          }
        }
      }
    }
  `);

  const {
    carousel,
    lists,
  } = data.interiorJson.bannerData;

  const glideOptions = {
    type: 'carousel',
    perView: 3,
    gap: 20,
    breakpoints: {
      1200: {
        perView: 2,
      },
      667: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  const [state, setState] = useState({ email: '', valid: '' });
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line

  const handleOnChange = (e) => {
    let value = '';
    if (e.target.value.match(emailRegex)) {
      if (e.target.value.length > 0) {
        value = e.target.value;
        setState({ ...state, email: value, valid: 'valid' });
      }
    } else {
      if (e.target.value.length > 0) {
        setState({ ...state, valid: 'invalid' });
      } else {
        setState({ ...state, valid: '' });
      }
    }
  };

  const handleSubscriptionForm = (e) => {
    e.preventDefault();
    if (state.email.match(emailRegex)) {
      console.log(state.email);
      setState({ email: '', valid: '' });
    }
  };

  return (
    <BannerWrapper>
      <Container>
        <CarouselArea>
          {loading ? (
            <GlideCarousel
              carouselSelector="interior_carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {/* {carousel.map((item) => (
                  <GlideSlide key={`carousel_key${item.id}`}>
                    <a href={item.link} className="item_wrapper">
                      <Image
                        src={item.thumb_url.publicURL}
                        alt={item.title}
                        className="carousel_img"
                      />
                      <Heading as="h4" content={item.title} />
                    </a>
                  </GlideSlide>
                ))} */}
                {lists.map((item) => (
                  <GlideSlide key={`carousel_key${item.Id}`}>
                    <a href={`${item.Link}`} className="item_wrapper">
                      <Image
                        src={item.Poster}
                        alt={item.Title}
                        className="carousel_img"
                      />
                      <Heading as="h4" content={item.Title} />
                    </a>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          ) : (
            <CircleLoader>
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
